import React from "react"
import SEO from "../components/seo"
import GlobalStyle from "../components/globalStyle"
import styled from "styled-components"
import Hero from "../components/hero"

import P5 from "../components/p5/p5_3/p5"

const Container = styled.div``
const LineGeneration = () => (
  <Container>
    <SEO title="Line generation" />
    <GlobalStyle />
    <Hero />
    <P5 />
  </Container>
)

export default LineGeneration
